@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  background: #00001a;
}

ul {
  list-style-type: none;
}

button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: white;
}

.container {
  padding: 72px 0;
}

.row {
  max-width: 1200px;
  margin: 0 auto;
}

.center {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.back--btn {
  background-color: #d4af37;
  margin: 0 0 48px 0;
}

.unclickable {
  cursor: not-allowed !important;
}

/* NAVBAR */

nav {
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  max-width: 100px;
}

.nav__logo--img {
  width: 100%;
}

.nav__list {
  display: flex;
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.nav__list--link {
  padding-left: 24px;
  cursor: pointer;
}

/* HOME */

.search__header {
  text-align: center;
  margin-bottom: 72px;
  color: white;
}

.search__container {
  width: 90%;
}

.search-box {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  height: 40px;
  border-radius: 40px;
  display: flex;
}

.search-btn {
  color: white;

  width: 40px;
  height: 40px;

  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.4s;
}

.search-txt {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: black;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}

.search-icon {
  color: black;
  background: none;
  cursor: pointer;
}

.search-box:hover > .search-txt {
  width: 240px;
  padding: 0 12px;
}

.search-txt:focus {
  width: 240px;
  padding: 0 12px;
}

/* MOVIES */

.movies {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.movie {
  width: 30%;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;
}

.movie:hover {
  transform: scale(1.05);
}

.movie:hover .movie__title {
  /* visibility: visible;
  transform: translateY(0%); */
  opacity: 1;
}

.movie__title {
  padding-top: 12px;
  color: white;
  background: none;
  z-index: -50;
  /* transform: translateY(-100%); */
  /* visibility: hidden; */
  opacity: 0;
  transition: all 300ms ease;
}

.skeleton {
  width: 30%;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skeleton__poster {
  width: 100%;
  height: 445px;
  background-color: #f2eae9;
  margin-bottom: 8px;
}

.skeleton__title {
  width: 80%;
  height: 16px;
  background-color: #f2eae9;
}

.empty__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.empty__state {
  margin: 0 auto;
  padding-top: 32px;
  max-width: 420px;
}

.empty__state--img {
  width: 100%;
}

.empty__state--txt {
  color: white;
  font-size: 24px;
}

/* DESCRIPTION */

.desc__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 60px;
}

.back--btn--desc {
  background-color: #d4af37;
}

.desc__info {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.poster--wrapper {
  width: 30%;
}

.poster--wrapper--img {
  width: 100%;
}

.desc__title {
  color: white;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
}

.desc__details {
  color: white;
  margin-bottom: 24px;
}

.desc__plot {
  color: white;
}

.skeleton--desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton__poster--desc {
  width: 355px;
  height: 445px;
  background-color: #f2eae9;
}

.skeleton__info {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.skeleton__title--desc {
  width: 300px;
  height: 32px;
  background-color: #f2eae9;
  margin-bottom: 12px;
}

.skeleton__details {
  margin-bottom: 24px;
}

.skeleton__genre {
  width: 220px;
  height: 16px;
  background-color: #f2eae9;
  margin-bottom: 4px;
}

.skeleton__runtime {
  width: 60px;
  height: 18px;
  background-color: #f2eae9;
  margin-bottom: 4px;
}

.skeleton__rating {
  width: 52px;
  height: 16px;
  background-color: #f2eae9;
}

.skeleton__plot {
  width: 720px;
  height: 16px;
  background-color: #f2eae9;
  margin-bottom: 4px;
}

.skeleton__plot--two {
  width: 480px;
  height: 16px;
  background-color: #f2eae9;
}

@media (max-width: 1500px) {
  .nav__list {
    margin-right: 24px;
  }

  .nav__logo {
    margin-left: 24px;
  }

  .movies {
    padding: 0 32px;
  }
  .back--btn,
  .back--btn--desc {
    margin-left: 32px;
  }
}

@media (max-width: 1120px) {
  .movie {
    width: 50%;
    margin-bottom: 72px;
  }

  .desc__container {
    flex-direction: column;
  }

  .poster--wrapper {
    width: 50%;
    margin-bottom: 24px;
    padding-top: 12px;
  }

  .desc__info {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .movie {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .nav__list {
    display: none;
  }

  .poster--wrapper {
    width: 70%;
  }

  .desc__info {
    width: 80%;
  }
}
